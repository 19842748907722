<template>
  <div>
  <default-list
    :items="items()"
    class="mb-n2"
  />
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import { sync } from 'vuex-pathify'
  export default {
    name: 'DefaultAccountSettings',

    components: {
      DefaultList: () => import(
        /* webpackChunkName: "default-list" */
        '../List'
      )
    },

    data: () => ({
    }),
    computed: {
      ...mapGetters('user', ['shortDisplayName']),
      first_name: sync('user.first_name'),
      last_name: sync('user.last_name'),
    },
    methods: {
      items() {
        return [
        {
          title: this.shortDisplayName,
          icon: 'mdi-vuetify',
          items: [
            {
              title: 'My Profile',
            },
            {
              title: 'Edit Profile',
            },
            {
              title: 'Settings',
            },
          ],
        },
        ]
      }
    }
  }
</script>
